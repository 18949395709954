<template>
  <div>
    <headerNav activeIndex="3"></headerNav>
    <div class="designBody">
      <div class="designImg">
        <div style="padding-top:119px">创意设计服务</div>
        <div style="margin-top:32px;">
          <span style="width:620px;font-size: 40px;display:inline-block">从现在开始，用更富魅力的方法， 敲响客户的大门。</span>
        </div>
      </div>
      <div class="otherDiv">
        <div class="otherForm">
          <el-form ref="form" :model="form" label-width="140px">
            <el-row>
              <el-col :span="20">
                <el-form-item label="">
                  <el-input v-model="form.name" placeholder="您的姓名"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="20">
                <el-form-item label="">
                  <el-input v-model="form.phone" placeholder="联系方式"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="20">
                <el-form-item label="">
                  <el-input type="textarea"  resize="none"  placeholder="详情描述" maxlength="100" v-model="form.remark"  :autosize="{ minRows: 6}" show-word-limit></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-button type="primary" class="formBtn" @click="submitted">提交咨询</el-button>
          </el-form>
        </div>

      </div>

    </div>
    <contactNav></contactNav>
    <footerNav></footerNav>
  </div>
</template>

<script>
import headerNav from "@/components/headerNav.vue";
import footerNav from "@/components/footerNav.vue";
import contactNav from "@/components/contactNav.vue";
import { mapState, mapActions } from "vuex";
export default {
  name:'contact',
  components: {
    headerNav,
    footerNav,
    contactNav
  },
  data() {
    return {
      form:{
        name:'',
        phone:'',
        remark:'',
      },
      rules: {

      }
    };
  },
  methods: {
    submitted(){
      if(!this.form.name){
        this.$message({
          message: '请输入您的姓名!',
          type: 'warning'
        });
        return;
      }
      if(!this.form.phone){
        this.$message({
          message: '请输入您的联系方式!',
          type: 'warning'
        });
        return;
      }
      if(!this.form.remark){
        this.$message({
          message: '请输入您的详情描述!',
          type: 'warning'
        });
        return;
      }
      this.form.name = ''
      this.form.phone = ''
      this.form.remark = ''
      this.$message({
        message: '提交成功!',
        type: 'success'
      });
    },
  },
  computed: {

  },
  created() {

  },
  mounted() {

  },
};
</script>

<style lang="scss" scoped>
.designBody{
  width:100%;
  min-height: 800px;
  .designImg{
    width:100%;
    height:500px;
    background:url("../../assets/img/designImg.png") no-repeat center;
    background-size: 100% 100%;
  }
  .designImg div{
    width:1200px;
    margin:0 auto;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 80px;
    color: #FFFFFF;
    letter-spacing: 2px;
    text-align: left;
    font-style: normal;
  }
  .otherDiv{
    width:1200px;
    height: 1128px;
    margin: 160px auto;
    background: url("../../assets/img/otherImg.png") no-repeat center;
    background-size: 100% 100%;
    .otherForm{
      padding-top: 362px;
      .formBtn{
        margin-left: 145px;
        width: 550px;
        height: 50px;
        background: linear-gradient( 121deg, #6F98FF 0%, #3C71FE 100%);
        border-radius: 8px;
      }
    }
  }
}
</style>
